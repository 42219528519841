import React from "react";
import Helmet from "react-helmet";
import { navigate } from "gatsby";

import Layout from "../components/layout";

const Privacy = () => {
	React.useEffect(() => {
		navigate("/legal/privacy-policy/");
	}, []);

	return null;

	return (
		<Layout>
			<main>
				<Helmet>
					<title>Privacy Policy</title>
				</Helmet>

				<header className="aboveFold">
					<div className="container">
						<h1>Privacy Policy</h1>
					</div>
				</header>

				<section className="container container--text">
					<h2>Introduction</h2>
					<p className="u-mb4">
						When using Sidemail ("Service") you consent to the collection,
						transfer, storage, disclosure, and use of your information as
						described in this Privacy Policy.
					</p>

					<h2>Personal informations</h2>
					<ul className="list u-mb4">
						<li>
							When you sign up for Sidemail, we ask for your name, and email
							address. That’s just so you can personalize your new account, and
							we can send you invoices, updates, or other essential information.
							We’ll never sell your personal info to third parties.
						</li>
						<li>
							When you pay for Sidemail, we ask for your credit card. That’s so
							we can charge you for service and send you invoices. Your credit
							card is passed directly to our payment processor and doesn’t ever
							go through our servers.
						</li>
						<li>
							When you write to Sidemail with a question or to ask for help,
							we’ll keep that correspondence, and the email address, for future
							reference. We also store any information you volunteer, like
							surveys, for as long as we need.
						</li>
						<li>
							The Service may automatically collect information about your
							computer hardware and software (including your IP address, browser
							type, domain names, geolocation data or access times).
						</li>
						<li>
							We may store your data (including your images) on servers provided
							by third party hosting vendors.
						</li>
						<li>
							To investigate, prevent, or take action regarding illegal
							activities, suspected fraud, situations involving potential
							threats to the physical safety of any person, violations of our
							Terms of Service, or as otherwise required by law.
						</li>
					</ul>

					<h2>Security</h2>
					<p className="u-mb4">
						All data is encrypted via SSL/TLS when transmitted from our servers
						to your browser. However, no data transmission over the Internet can
						be guaranteed as totally secure. While we strive to protect such
						information we do not warrant and cannot ensure the security of
						information which you transmit to us.
					</p>

					<h2>Cookies</h2>
					<p className="u-mb4">
						Sidemail uses “cookie” technology to collect additional website
						usage data and to improve the Service and our service. Most web
						browsers automatically accept cookies, but you can usually modify
						this setting to decline cookies or to prompt you before accepting a
						cookie from the websites you visit. If you choose to decline
						cookies, you may not be able to fully experience some features of
						Sidemail.
					</p>

					<h2>Changes & Conclusion</h2>
					<p className="u-mb4">
						Sidemail may update this policy, we’ll notify you about significant
						changes by emailing you or by placing temporary notice on our site.
						If you have any addition questions about this Privacy policy,
						contact us.
					</p>

					<p className="u-mb4">
						This Privacy Policy is effective as of January 1, 2019
					</p>
				</section>
			</main>
		</Layout>
	);
};

export default Privacy;
